<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('faqs_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('faqs_summary')"></div>

  <ng-container *ngFor="let group of faqGroups">
    <app-faqgroup [faqGroup]="group"></app-faqgroup>
  </ng-container>
</div>
