<div class="row" id="complaintNoSubscriptionHeader">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('complaint_intro')"></h4>
  </div>
  <div *ngIf="isDeactivated" class="col-12 mb-3" [innerHTML]="translationService.getTranslation('complaint_deactivated_description')">
  <div *ngIf="!isDeactivated" class="col-12 mb-3" [innerHTML]="identified? translationService.getTranslation('complaint_description') : translationService.getTranslation('unauthorized_provideInfo_description' )">
  </div>
</div>
<div class="row" *ngIf="!isDeactivated">
  <div class="col-12 complaintNoSubscriptionErrors">
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div id="complaintNoSubscription">
      <ng-container class="col-12" *ngIf="useIdentificationForm; else withoutIdentification">
        <div class="col-12" *ngIf="!identified">
          <button mat-flat-button
                  class="btn btn-secondary"
                  (click)="navigateToIdentification()"
                  [innerHTML]="translationService.getTranslation('button_toIdentificationForm')"></button>
        </div>
        <div *ngIf="isLoadingSub" style="text-align: center;">
          <app-loading-spinner></app-loading-spinner>
        </div>
      </ng-container>
      <ng-template #withoutIdentification>
        <app-client-search (validateButtonClick)="loadOrdersForClientno($event)" [isLoading]="isLoadingSub">
          <mat-error *ngIf="clientNoForm.get('clientNo').hasError('message')">{{ clientNoForm.get('clientNo').getError('message') }}</mat-error>
        </app-client-search>
      </ng-template>
      <div *ngIf="noOrdersFound" class="mb-3" [innerHTML]="translationService.getTranslation('complaint_cannot_find_clientno')"></div>
      <ng-container *ngIf="!isLoadingComp && !isLoadingSub && orders.length > 0;">
        <mat-card appearance="outlined" *ngFor="let sub of orders; let i = index" class="mt-3">
          <div class="row m-3">
            <div class="col-6">
              <mat-card-title>{{sub.productDescription}}</mat-card-title>
              <div class="row">
                <div class="form-group col-12">
                  <div class="row">
                    <div class="col-6" [innerHTML]="translationService.getTranslation('label_backendid')">
                    </div>
                    <div class="col-6">
                      {{ sub.userBackendId }}
                    </div>
                  </div>
                </div>
                <div class="form-group col-12">
                  <div class="row">
                    <div class="col-6" [innerHTML]="translationService.getTranslation('order_id')">
                    </div>
                    <div class="col-6" *ngIf="sub.backendId <= 0" [innerHTML]="translationService.getTranslation('no_order_id')">
                    </div>
                    <div class="col-6" *ngIf="sub.backendId > 0">
                      {{ sub.backendId }}
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="sub.variantDescription">
                  <div class="form-group col-12">
                    <div class="row">
                      <div class="col-6" [innerHTML]="translationService.getTranslation('order_edition')">
                      </div>
                      <div class="col-6">
                        {{ sub.variantDescription }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="form-group col-12">
                  <div class="row">
                    <div class="col-6" [innerHTML]="translationService.getTranslation('order_product')"></div>
                    <div class="col-6">{{ sub.productDescription? sub.productDescription : sub.productCode }}</div>
                  </div>
                </div>
                <div class="form-group col-12">
                  <div class="row">
                    <div class="col-6" [innerHTML]="translationService.getTranslation('address_delivery')">
                    </div>
                    <div class="col-6">
                      {{ sub.deliveryAddressList.values().next().value.address.street }} {{ houseNoPipe.transform(sub.deliveryAddressList.values().next().value.address.houseno) }}
                    </div>
                  </div>
                </div>
                <div class="form-group col-12">
                  <div class="row">
                    <div class="col-6">
                    </div>
                    <div class="col-6">
                      {{ sub.deliveryAddressList.values().next().value.address.zipcode }} {{ sub.deliveryAddressList.values().next().value.address.city }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <mat-card-actions>
                <button mat-raised-button color="primary"
                        (click)="onNewComplaint(sub)"
                        [innerHTML]="translationService.getTranslation('button_new_complaint')">
                </button>
              </mat-card-actions>
            </div>
          </div>

        </mat-card>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="showContactFormOnComplaintViewWithoutLogin && !isLoadingComp && !isLoadingSub && orders.length == 0">
  <div class="row mt-3 mb-3">
    <div class="col-12 mb-2" [innerHTML]="translationService.getTranslation('complaint_noClientno')">
    </div>
  </div>
  <button mat-flat-button
          class="btn btn-secondary float-end"
          (click)="navigateToContact()"
          [innerHTML]="translationService.getTranslation('complaint_ToContactForm')">
  </button>
</div>
