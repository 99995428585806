<div class="row" *ngIf="defaultCountry !== 'NL'; else nl">
  <mat-form-field appearance="fill" class="ps-2">
    <mat-label [innerHTML]="translationService.getTranslation('label_backendid')"></mat-label>
    <input maxLength="50" (input)="loadClient()" matInput type="text" [(ngModel)]="clientno" #clientNo>
    <mat-hint *ngIf="clientNo === document?.activeElement && clientNo.value.length >=1 " align="end">{{clientNo.value.length}} / {{clientNo.maxLength}}</mat-hint>
    <button *ngIf="!validationIsActive" matSuffix mat-icon-button (click)="validateClientAndEmit()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</div>
<ng-template #nl>
  <div class="row">
    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label [innerHTML]="translationService.getTranslation('address_postcode')"></mat-label>
        <input matInput type="text" [(ngModel)]="zipCode" (keyup)="validateZipCodeNL()" pattern="\d{4}[A-Z]{2}">
        <mat-hint>{{translationService.getTranslation('clientSearch_helper_postcode')}}</mat-hint>
        <mat-error>{{translationService.getTranslation('clientSearch_helper_postcode')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
        <input matInput type="text" [(ngModel)]="houseNumber" (keyup)="validateHouseNoNL()" pattern="\d{1,5}">
        <mat-hint>{{translationService.getTranslation('clientSearch_helper_houseNo')}}</mat-hint>
        <mat-error>{{translationService.getTranslation('clientSearch_helper_houseNo')}}</mat-error>
        <button *ngIf="zipCodeNlValid && houseNoNlValid" matSuffix mat-icon-button (click)="loadClientNl()">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<div *ngIf="((validationIsActive && defaultCountry !== 'NL')  || (defaultCountry === 'NL' && validationIsActive && !onlyOneResult)) && clientSelected" class="row">
  <form class="searchForm" [formGroup]="validationForm" (ngSubmit)="validateClientAndEmit()">
    <ng-container *ngFor="let search_factor of validationFactors">
      <mat-form-field appearance="fill"
                      [ngClass]="validationFactors.length === 1 ? 'col-12 ps-2' : 'col-12 col-md-6 ps-2'">
        <mat-label
          [innerHTML]="translationService.getTranslation(search_factor != 'zipcode' ? 'address_' + search_factor : 'address_postcode')"></mat-label>
        <input matInput type="text" formControlName="{{search_factor}}">
      </mat-form-field>
    </ng-container>
  </form>

  <div *ngIf="!isLoading && !validationSuccessful" class="row">
    <div class="col-3">
      <button (click)="validateClientAndEmit()" mat-raised-button
              color="primary">{{ translationService.getTranslation('step_check') }}
      </button>
    </div>
  </div>
</div>

<div class="alert alert-warning row mb-2 mt-3" *ngIf="clientnoNotFound || inputInvalid">
  <div class="col-12">
    <div [innerHTML]="translationService.getTranslation('complaint_cannot_find_clientno')">
    </div>
  </div>
</div>

<div *ngIf="isLoading" style="text-align: center;">
  <app-loading-spinner [type]="'small'"></app-loading-spinner>
</div>
