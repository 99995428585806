import {Component, Input, OnInit} from '@angular/core';
import {FaqGroup} from '../../data/models/faqGroup.model';
import {FaqService} from '../../core/services/faq.service';
import {TranslationService} from "../../core/services/translation.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @Input() public faqGroups: FaqGroup[] = [];

  constructor(private faqService: FaqService, public translationService: TranslationService) {
  }

  ngOnInit() {
    this.faqGroups = this.faqService.getFaqGroups();
  }
}
