import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslationService} from '../../../core/services/translation.service';
import {HupSubscriptionsService} from '../../../data/services/hup-subscriptions.service';
import {BaseDataService} from '../../../core/services/base-data.service';
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../../core/services/config.service";
import {HupSubscription} from "../../../data/models/subscription.model";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {HouseNoPipe} from "../../../core/pipes/houseno.pipe";
import {LandingPageService} from "../../../data/services/landing-page.service";
import {RedirectService} from "../../../data/services/redirect.service";
import {LandingPage} from "../../../data/models/landing-page.model";

@Component({
  selector: 'app-complaint-wihtout-login',
  templateUrl: './complaint-wihtout-login.component.html',
  styleUrls: ['./complaint-wihtout-login.component.scss'],
  providers: [HouseNoPipe]
})
export class ComplaintWihtoutLoginComponent implements OnInit {
  errorsArr: string[] = [];
  isMobile: boolean = false;
  clientNoForm: FormGroup;
  orderSelectForm: FormGroup;
  orders: HupSubscription[] = [];
  isLoadingComp: boolean = false;
  isLoadingSub: boolean = false;
  isDeactivated: boolean = false;
  noOrdersFound: boolean = false;
  showContactFormOnComplaintViewWithoutLogin: boolean = true;
  useIdentificationForm: boolean = false;
  identified: boolean = false;

  constructor(
    public translationService: TranslationService,
    private formBuilder: FormBuilder,
    private subscriptionService: HupSubscriptionsService,
    private baseDataService: BaseDataService,
    private navigationService: NavigationService,
    private landingPageService: LandingPageService,
    private redirectService: RedirectService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    public houseNoPipe: HouseNoPipe
  ) {
    this.clientNoForm = this.formBuilder.group({
      clientNo: ['', Validators.required]
    });

    this.configService.loadConfig('showContactFormOnComplaintViewWithoutLogin').subscribe(config => {
      if ( config) {
        this.showContactFormOnComplaintViewWithoutLogin = (config.value === 'true');
      }
    });
    this.configService.loadConfig('withoutLogin.useIdentificationForm').subscribe(config => {
      if( config) {
        this.useIdentificationForm = (config.value === 'true' || config.value === '1');
      }
    });

    this.route.paramMap.subscribe((params) => {

      const clientNo : number = Number(params.get('clientNo'));
      if(clientNo) {
        this.identified = true;
        this.loadOrdersForClientno(clientNo);
      }
    });
    this.configService.loadConfig('complaint.deactivate.begin').subscribe(configBegin => {
      if (configBegin) {
        this.configService.loadConfig('complaint.deactivate.end').subscribe(configEnd => {
          const begin = configBegin.value.split(',', 3);  // 0,11,30
          const end = configEnd.value.split(',', 3);      // 6,11,30
          this.isDeactivated = this.isTodayBetweenWeekdays(+begin[0], +begin[1], +begin[2], +end[0], +end[1], +end[2]);
        });
      }
    });
  }

  isTodayBetweenWeekdays(
    startDay: number, startHour: number, startMinute: number,
    endDay: number, endHour: number, endMinute: number
  ): boolean {
    const now = new Date();
    const currentDay = now.getDay(); // 0 (Sonntag) bis 6 (Samstag)
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    const startMinutes = startDay * 1440 + startHour * 60 + startMinute;
    const endMinutes = endDay * 1440 + endHour * 60 + endMinute;
    const nowMinutes = currentDay * 1440 + currentMinutes;

    return startMinutes <= endMinutes
      ? nowMinutes >= startMinutes && nowMinutes <= endMinutes
      : nowMinutes >= startMinutes || nowMinutes <= endMinutes;
  }

  ngOnInit(): void {
  }

  onSend(): void {

  }

  loadOrdersForClientno(clientNo: number): void {
    this.isLoadingSub = true;
    this.orders = [];
    this.noOrdersFound = false;
    if (clientNo && Number(clientNo) > 0) {
      this.subscriptionService.getSubscriptionsUnauthorized(clientNo).subscribe(orders => {
        this.isLoadingSub = false;
        if (orders.length === 1 && orders[0]) {
          this.onNewComplaint(orders[0]);
        } else if (orders.length > 1) {
          orders.forEach(order => {
            this.baseDataService.handleUnauthorizedOrder(order);
            if(!order.variantDescription) {
              this.baseDataService.loadBaseData('variantcode').subscribe(
                (bds) => {
                  bds?.forEach(bd => {
                    if(bd.key === order.variantCode) {
                      order.variantDescription = bd.description;
                    }
                  })
                }
              );
            }
            this.orders.push(order);
          });
        } else {
          this.noOrdersFound = true;
        }
      });
    }
  }

  onNewComplaint(subscription: HupSubscription): void {
    this.navigationService.navigateTo(NavigationCommand.COMPLAINTWITHPUTLOGINNEW, [subscription.backendId + '', subscription.userBackendId + '', 'true', 'true', (subscription.offer.offerType.ePaper ? 'false' : 'true')]);
  }

  navigateToContact(): void {
    this.navigateTo(NavigationCommand.CONTACT)
  }

  navigateToIdentification(): void {
    this.navigateTo(NavigationCommand.IDENTIFICATION, [NavigationCommand.COMPLAINT.substring(1)]);
  }

  navigateTo(subPath: NavigationCommand, params: string[] = []) {
    this.landingPageService.getLandingPages().subscribe(pages => {
      let foundPage : LandingPage = null;
      pages.forEach(page => {
        if (page && subPath.endsWith(page.link)) {
          foundPage = page;
        }
      });
      if(foundPage && foundPage.isExtern) {
        this.redirectService.redirect(foundPage.externLink, true);
      } else {
        this.navigationService.navigateTo(subPath, params);
      }
    });
  }

}
