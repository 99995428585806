import {Component, Input} from '@angular/core';
import {FaqGroup} from '../../data/models/faqGroup.model';

@Component({
  selector: 'app-faqgroup',
  templateUrl: './faqgroup.component.html',
  styleUrls: ['./faqgroup.component.css']
})
export class FaqGroupComponent {
  @Input() public faqGroup: FaqGroup;
}
