import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FaqGroup} from "../../data/models/faqGroup.model";

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private httpClient: HttpClient) {
  }

  loadFaqGroups(): Observable<FaqGroup[]> {
    return this.httpClient.get<FaqGroup[]>('/webabo/faq/groups/active');
  }

  getFaqGroups(): any {
    let faqGroups: FaqGroup[] = [];

    this.loadFaqGroups().subscribe(
      (groups: FaqGroup[]) => faqGroups = groups,
      (error) => console.error('Couldn´t load FAQ-Groups.', error),
      () => console.log('Groups have been loaded.')
    );
    return faqGroups;
  }
}
