<div class="mb-2 mt-3">
  <div *ngIf="faqGroup.faqEntries.length > 0">
    <h5 class="fw-bold">{{ faqGroup.name }}</h5>
    <p class="fs-6">{{ faqGroup?.description }}</p>
    <div class="ms-1">
      <div *ngFor="let entry of faqGroup.faqEntries">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="fs-6 mb-0 fw-bold">{{ entry.question }}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p [innerHTML]="entry.answer"></p>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>
