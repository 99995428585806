import {Component, OnInit} from '@angular/core';
import {HupSubscriptionsService} from '../../data/services/hup-subscriptions.service';
import {TranslationService} from '../../core/services/translation.service';
import {SubChangeService} from '../../data/services/sub-change.service';
import {HupSubscription} from '../../data/models/subscription.model';
import {ActivatedRoute} from '@angular/router';
import {SubChange} from '../../data/models/sub-change.model';
import {NavigationCommand, NavigationService} from '../../data/services/navigation.service';
import {LoginService} from '../../core/components/login/login.service';

@Component({
  selector: 'app-sub-change',
  templateUrl: './sub-change.component.html',
  styleUrls: ['./sub-change.component.scss']
})
export class SubChangeComponent implements OnInit {
  isLoadingSub: boolean = false;
  subs: HupSubscription[] = [];
  errorsArr: string[] = [];

  constructor(private subChangeService: SubChangeService,
              private hupSubscriptionsService: HupSubscriptionsService,
              public translationService: TranslationService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.loginService.getAuthData().subscribe((authData) => {
      this.isLoadingSub = true;
      if (!!authData && (authData.token !== null)) {

        this.hupSubscriptionsService.getSubscriptions().subscribe(subs => {
          this.isLoadingSub = false;
          this.subs = [];
          subs.forEach(sub => {
            if ((sub.validDate.validUntil == null || sub.validDate.validUntil > new Date()) && sub.backendId > 0) {
              if (!sub.offer?.offerType?.ePaper) {
                this.subs.push(sub);
              }
            }
          });

          this.subs.forEach(sub => {
            sub.isLoadingSubChange = true;

            this.subChangeService.getSubChanges(sub.backendId, -1, true).subscribe({
              next: (res) => {
                sub.subChanges = res;
              },
              error: (error) => {
                sub.isLoadingSubChange = false;
                this.errorsArr.push(this.translationService.getTranslation('error_subchanges_init'));
              },
              complete: () => {
                sub.isLoadingSubChange = false;
                sub.subChanges.forEach(subChange => {
                  subChange.outdated = new Date(subChange.validDate.validFrom).getTime() <= new Date().getTime();
                });
              }
            });
          });

        }, error => {
          this.isLoadingSub = false;
          this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
        });
      }
    });
  }

  onDelete(sub: HupSubscription, subchange: SubChange): void{
    this.subChangeService.deleteSubChange(subchange.webId, sub.subId, subchange.backendId);
    this.hupSubscriptionsService.loadedAll = false;
    this.ngOnInit();
  }

  onNewSubChange(sub: SubChange): void {
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGENEW, [sub.webId + '', sub.backendId + '']);
  }

  onEdit(sub: HupSubscription, subChange: SubChange): void{
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGEEDIT, [subChange.webId + '', subChange.backendId + '', 'true', sub.backendId + '']);
  }
}
