import {
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnChanges,
  OnInit,
  Output, PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TranslationService} from "../../../core/services/translation.service";
import {Offer} from "../../../data/models/offer.model";
import {ConfigService} from "../../../core/services/config.service";
import {Bonus} from "../../../data/models/bonus.model";
import {OfferPrice} from "../../../data/models/price.model";
import {BaseData} from "../../../data/models/base-data.model";
import {MatStepper} from "@angular/material/stepper";
import {DomSanitizer} from "@angular/platform-browser";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {PaymentProviderService} from "../../../core/services/payment-provider.service";
import {PaymentTransactionModel} from "../../../core/components/onlinepayment/paymentTransaction.model";
import {isPlatformBrowser} from "@angular/common";
import {OrderResultModel} from "../../../data/models/orderResult.model";

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.css']
})
export class OfferCardComponent implements OnInit, OnChanges {

  @Input() offer: Offer;
  @Input() showOnOfferPage: boolean = true;
  @Input() bonus: Bonus[];
  @Input() deliveryAddress: any;
  @Input() billingAddress: any;
  @Input() bonusAddress: any;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() prices: OfferPrice[];
  @Input() paymentCode: BaseData;
  @Input() paymentFrequency: BaseData;
  @Input() stepper: MatStepper;
  @Input() creatingOrder: boolean = false;
  @Input() onlinePayment: boolean = false;
  @Input() privacyDisclaimerAgbInOne: boolean = false;
  @Output() save: EventEmitter<OrderResultModel> = new EventEmitter<OrderResultModel>();
  highlightIds: string[];
  @ViewChild('saveButtonPoint')saveButtonPoint: ElementRef<HTMLElement>;
  @ViewChild('offerCard')offerCard: ElementRef<HTMLElement>;
  orderResult: OrderResultModel = {agb: false, privacy: false, disclaimer: false, paymentTransaction: null};

  agb: boolean = false;
  privacy:boolean = false;
  disclaimer: boolean = false;
  privacyDisclaimerAgb:boolean = false;
  checkBoxes: boolean = false;

  constructor(
    private navigationService: NavigationService,
    public translationService: TranslationService,
    public configService: ConfigService,
    public sanitizer: DomSanitizer,
    private paymentProvider: PaymentProviderService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const offerIds = this.configService.getConfig('offer.highlight.offerId');
    if (offerIds?.value !== '') {
      this.highlightIds = offerIds?.value?.split(',');
    }

    this.paymentProvider.getPaymentProviderResultData().subscribe(transaction => {
      if (transaction && transaction?.transactionId && transaction.transactionId !== '') {
        this.orderResult.paymentTransaction = transaction;
        this.saveOrder();
      }
    });
  }

  checkBoxesChecked(): boolean {
    this.checkBoxes = true;
    return (this.agb && this.privacy && this.disclaimer || this.privacyDisclaimerAgb);
  }

  ngOnInit(): void {
  }

  openOrder(id: number): void{
    this.navigationService.navigateTo(NavigationCommand.OFFER, new Array(id+''));
  }

  saveOrder(): void {
    if (this.checkBoxesChecked()) {
      this.orderResult.agb = true;
      this.orderResult.privacy = true;
      this.orderResult.disclaimer = true;
      this.save.emit(this.orderResult);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId) && changes.prices && !changes.prices.firstChange) {
      const elementPosition = this.saveButtonPoint?.nativeElement?.getBoundingClientRect()?.top;
      const cardPosition = this.offerCard?.nativeElement?.getBoundingClientRect();
      const absoluteElementTop = cardPosition?.top + window.pageYOffset;
      const middle = absoluteElementTop - (cardPosition.height / 2);
      const bodyPosition = document?.body?.getBoundingClientRect()?.top;
      if ( elementPosition && elementPosition > 0) {
        let offset = 550;
        if ( this.stepper.orientation === 'vertical') {
          offset = 350;
        }
        const offsetPosition = elementPosition - offset - bodyPosition;
        //window.scrollTo({top: offsetPosition, behavior: "smooth"});
        window.scrollBy(0, middle);
      }
    }
  }
}
