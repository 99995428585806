export function getHouseNo(houseNo: string, countryKey: string, stock?: string, stiege?: string, tuer?: string): string {
  if (countryKey === 'A' || countryKey === 'AT') {
    return '01#'.concat(houseNo)
      .concat('#').concat(stiege ? stiege : '')
      .concat('#').concat(stock ? stock : '')
      .concat('#').concat(tuer ? tuer : '');
  } else {
    return '00#'.concat(houseNo).concat('###');
  }
}

export function transformHouseNo(houseno: string): string {
  if (houseno && houseno.startsWith('01')) {
    return houseno.replace('01#', '').split('#').join('/');
  } else if (houseno && houseno.startsWith('00')) {
    return houseno.replace('00#', '').split('#').join( '');
  } else {
    return houseno;
  }
}
